// XX团队投研进行时
const routes = [
	{
		path: '/team/:type',
        component: () => import("@/views/team_working/index.vue"),
		meta: {
			only_wechat: true
		}
	},
	{
		path: '/industry_detail/:id',
		component: () => import("@/views/team_working/industry_detail/index.vue"),
		meta: {
			only_wechat: true
		}
	},
	{
		path: '/forum_detail/:id',
		component: () => import("@/views/team_working/forum_detail/index.vue"),
		meta: {
			only_wechat: true
		}
	}
]

export default routes