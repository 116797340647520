const routes = [
    {
        path: '/h5/dzp/:sign',
        component: () => import("@/views/h5/dzp/index.vue"),
    },
    {
        path: '/h5/zjd/:sign',
        component: () => import("@/views/h5/zjd/index.vue"),
    },
    {
        path: '/h5/jbx/:sign',
        component: () => import("@/views/h5/jbx/index.vue"),
    },

]

export default routes