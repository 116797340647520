const routes = [
    {
        path: "/30/dzp",
        children: [
            {
                path: "2024061701/cs/:test?",
                component: () => import("@/views/30/dzp/2024061701/index.vue"),
                meta: {
                    sign: "20240617cs1",
                },
            },
            {
                path: "2024061701/cwd/:test?",
                component: () => import("@/views/30/dzp/2024061701/index.vue"),
                meta: {
                    sign: "20240617cwd1",
                },
            },
            {
                path: "2024061702/gl/:test?",
                component: () => import("@/views/30/dzp/2024061702/index.vue"),
                meta: {
                    sign: "20240617gl2",
                },
            },
            {
                path: "2024061702/qbb/:test?",
                component: () => import("@/views/30/dzp/2024061702/index.vue"),
                meta: {
                    sign: "20240617qbb2",
                },
            },
            {
                path: "2024081901/jgf/:test?",
                component: () => import("@/views/30/dzp/2024081901/index.vue"),
                meta: {
                    sign: "20240819jgf1",
                },
            },
            {
                path: "2024081901/ypk/:test?",
                component: () => import("@/views/30/dzp/2024081901/index.vue"),
                meta: {
                    sign: "20240819ypk1",
                },
            },
            {
                path: "2024081902/hy/:test?",
                component: () => import("@/views/30/dzp/2024081902/index.vue"),
                meta: {
                    sign: "20240819hy2",
                },
            },
            {
                path: "2024081902/zx/:test?",
                component: () => import("@/views/30/dzp/2024081902/index.vue"),
                meta: {
                    sign: "20240819zx2",
                },
            },
        ],
    },
];

export default routes;
