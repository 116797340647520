import { createRouter, createWebHistory } from "vue-router";

import Permission from './permission.js'

// 获取router文件
let modulesFiles = require.context('./modules', true, /\.js$/), router_modules = []
modulesFiles.keys().map(v => router_modules = router_modules.concat(modulesFiles(v).default))

const routes = [
	{
		path: "/bgh/:sign?",
		name: "bgh",
		component: () => import("@/views/bgh/index.vue"),
	},
	{
		path: "/lotterybgh/:sign?",
		name: "lotterybgh",
		component: () => import("@/views/lotterybgh/index.vue"),
	},
	{
		path: "/yhdj/:sign?",
		name: "yhdj",
		component: () => import("@/views/yhdj/index.vue"),
	},
	{
		path: "/djs/:sign?",
		name: "djs",
		component: () => import("@/views/djs/index.vue"),
	},
	...router_modules,
	{
		path: '/not_wechat',
		name: 'not_wechat',
		component: () => import("@/views/not_wechat/index.vue"),
	},
	{
		path: "/:pathMatch(.*)*", // 官网提供 https://router.vuejs.org/zh/guide/essentials/dynamic-matching.html#%E6%8D%95%E8%8E%B7%E6%89%80%E6%9C%89%E8%B7%AF%E7%94%B1%E6%88%96-404-not-found-%E8%B7%AF%E7%94%B1
		name: "404",
		hidden: true,
		component: () => import("@/views/404/index.vue"),
	},
];

const router = createRouter({
	history: createWebHistory('/'),
	routes
});

new Permission(router)

export default router;
