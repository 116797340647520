import { createApp } from 'vue'

import router from '@/router/index.js'

import Vant from 'vant'
import 'vant/lib/index.css';
import 'amfe-flexible'

import VConsole from 'vconsole';
if(process.env.VUE_APP_VCONSOLE == 'true') var vConsole = new VConsole();

import App from './App.vue'
const app = createApp(App)
app.use(router)
app.use(Vant)
app.mount('#app')