<template>
	<router-view />
</template>

<style lang="scss">
	html, body, #app {
		height: auto;
		min-height: 100vh;
		margin: 0;
		padding: 0;
		outline: none;
	}
</style>