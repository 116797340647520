const routes = [
    {
        path: '/40/dzp',
        children: [
            {
                path: '20240415/general/:test?',
                component: () => import("@/views/40/dzp/20240415/index.vue"),
                meta: {
                    sign: '20240415general_kh'
                }
            }
        ]
    },
    {
        path: '/40/dzp',
        children: [
            {
                path: '20240722jxp/:test?',
                component: () => import("@/views/40/dzp/20240722jxp/index.vue"),
                meta: {
                    sign: '20240722jxp'
                }
            }
        ]
    }
]

export default routes