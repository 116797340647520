class Permission {
    constructor(router) {
        router.beforeEach((to, from, next) => {

            const userAgent = navigator.userAgent.toLowerCase()
            if(userAgent.indexOf('micromessenger') == -1 && to.meta?.only_wechat) {
                next({path: '/not_wechat', replace: true})
                return
            }

            next()
        })
    }
}

export default Permission